import { Form, Input, Button } from 'antd';
import React from 'react';

interface ContactFormProps {
  name: string;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required : '${label} is required.',
  types    : {
    email : '${label} is not a valid email.',
  },
  number : {
    range : '${label} must be at least ${min} characters long.',
  },
};
/* eslint-enable no-template-curly-in-string */

export default function ContactForm (props: ContactFormProps): JSX.Element {
  const { name } = props;

  // function storeValue (event: { target: (HTMLInputElement|HTMLTextAreaElement) }) {
  //   window.localStorage.setItem(event.target.id, event.target.value);
  // }

  // function clearStore () {
  //   // keep name and email for convenience
  //   window.localStorage.removeItem(`${name}-message`);
  // }

  // useEffect(() => {
  //   function loadValuesFromStore (fieldName: string) {
  //     const id    = `${name}-${fieldName}`;
  //     const input = window.document.getElementById(id);

  //     if (input) {
  //       (input as (HTMLInputElement|HTMLTextAreaElement)).value = (window.localStorage.getItem(id) || '');
  //     }
  //   }

  //   loadValuesFromStore('name');
  //   loadValuesFromStore('email');
  //   loadValuesFromStore('message');
  // }, [name]);

  return (
    <Form
      className="contact-form"
      layout="vertical"
      size="large"
      name={name}
      method="POST"
      validateMessages={validateMessages}
      data-netlify="true"
      data-netlify-recaptcha="true"
      netlify-honeypot="hp"
      // onFinish={clearStore}
    >
      <input type="hidden" name="hp" />
      <input type="hidden" name="form-name" value={name} />

      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, min: 2 }]}
      >
        <Input
          id={`${name}-name`}
          // onChange={storeValue}
        />
      </Form.Item>

      <Form.Item
        label="Email Address"
        name="email"
        rules={[{ required: true, type: 'email' }]}
      >
        <Input
          id={`${name}-email`}
          // onChange={storeValue}
        />
      </Form.Item>

      <Form.Item
        label="Message"
        name="message"
        rules={[{ required: true, min: 10 }]}
      >
        <Input.TextArea
          id={`${name}-message`}
          // onChange={storeValue}
        />
      </Form.Item>

      <div data-netlify-recaptcha="true"></div>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Send
        </Button>
      </Form.Item>
    </Form>
  );
}
