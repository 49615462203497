import React from 'react';

import Clipboard from '../Clipboard';

export default function CharacterMap (): JSX.Element {
  return (
    <>
      <h3>Linguistic Symbols</h3>
      <div>
        <Clipboard className="character-map-character">ː</Clipboard>
        <Clipboard className="character-map-character">ˊ</Clipboard>
        <Clipboard className="character-map-character">`</Clipboard>
        <Clipboard className="character-map-character">ʽ</Clipboard>
        <Clipboard className="character-map-character">→</Clipboard>
        <Clipboard className="character-map-character">ʔ</Clipboard>
        <Clipboard className="character-map-character">ø</Clipboard>
        <Clipboard className="character-map-character">¢</Clipboard>
      </div>

      <h3>Superscripts</h3>
      <div>
        <Clipboard className="character-map-character">ʰ</Clipboard>
        <Clipboard className="character-map-character">ⁿ</Clipboard>
        <Clipboard className="character-map-character">ᵐ</Clipboard>
        <Clipboard className="character-map-character">⁰</Clipboard>
        <Clipboard className="character-map-character">¹</Clipboard>
        <Clipboard className="character-map-character">²</Clipboard>
        <Clipboard className="character-map-character">³</Clipboard>
        <Clipboard className="character-map-character">⁴</Clipboard>
        <Clipboard className="character-map-character">⁵</Clipboard>
        <Clipboard className="character-map-character">⁶</Clipboard>
        <Clipboard className="character-map-character">⁷</Clipboard>
        <Clipboard className="character-map-character">⁸</Clipboard>
        <Clipboard className="character-map-character">⁹</Clipboard>
      </div>

      <h3>Subscripts</h3>
      <div>
        <Clipboard className="character-map-character">₀</Clipboard>
        <Clipboard className="character-map-character">₁</Clipboard>
      </div>

      <h3>Lexical Symbols</h3>
      <div>
        <Clipboard className="character-map-character">•</Clipboard>
        <Clipboard className="character-map-character">‣</Clipboard>
      </div>

      <h3>Vowel Diacritics</h3>
      <table className="character-map-table">
        <thead>
          <tr>
            <th>Standard</th>
            <th>Macron</th>
            <th>Acute</th>
            <th>Ogonek</th>
            <th>Acute + Ogonek</th>
            <th>Breve</th>
            <th>Circumflex</th>
            <th>Umlaut</th>
            <th>Grave</th>
            <th>Strikethrough</th>
        <th>Dot Below</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ā</Clipboard></td>
            <td><Clipboard className="character-map-character">á</Clipboard></td>
            <td><Clipboard className="character-map-character">ą</Clipboard></td>
            <td><Clipboard className="character-map-character">ą́</Clipboard></td>
            <td><Clipboard className="character-map-character">ă</Clipboard></td>
            <td><Clipboard className="character-map-character">â</Clipboard></td>
            <td><Clipboard className="character-map-character">ä</Clipboard></td>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">ạ</Clipboard></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ē</Clipboard></td>
            <td><Clipboard className="character-map-character">é</Clipboard></td>
            <td><Clipboard className="character-map-character">ę</Clipboard></td>
            <td><Clipboard className="character-map-character">ę́</Clipboard></td>
            <td><Clipboard className="character-map-character">ĕ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ī</Clipboard></td>
            <td><Clipboard className="character-map-character">í</Clipboard></td>
            <td><Clipboard className="character-map-character">į</Clipboard></td>
            <td><Clipboard className="character-map-character">į́</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ō</Clipboard></td>
            <td><Clipboard className="character-map-character">ó</Clipboard></td>
            <td><Clipboard className="character-map-character">ǫ</Clipboard></td>
            <td><Clipboard className="character-map-character">ǫ́</Clipboard></td>
            <td></td>
            <td><Clipboard className="character-map-character">ô</Clipboard></td>
            <td><Clipboard className="character-map-character">ö</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ū</Clipboard></td>
            <td><Clipboard className="character-map-character">ú</Clipboard></td>
            <td><Clipboard className="character-map-character">ų</Clipboard></td>
            <td></td>
            <td><Clipboard className="character-map-character">ŭ</Clipboard></td>
            <td><Clipboard className="character-map-character">û</Clipboard></td>
            <td><Clipboard className="character-map-character">ü</Clipboard></td>
            <td><Clipboard className="character-map-character">ù</Clipboard></td>
            <td><Clipboard className="character-map-character">ʉ</Clipboard></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">É</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ɛ</Clipboard></td>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">ɛ̨</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ə</Clipboard></td>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">ə̨</Clipboard></td>
            <td><Clipboard className="character-map-character">ə̨́</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">æ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ʊ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <h3 id="consonant-diacritics">Consonant Diacritics</h3>
      <table className="character-map-table">
        <thead>
          <tr>
            <th>Standard</th>
            <th>Dot Below</th>
            <th>Circumflex</th>
            <th>Circumflex + Dot Below</th>
            <th>Acute</th>
            <th>Cedilla</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">č</Clipboard></td>
            <td><Clipboard className="character-map-character">č̣</Clipboard></td>
            <td></td>
            <td><Clipboard className="character-map-character">ç</Clipboard></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">ǰ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ḳ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">p̣</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">š</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td><Clipboard className="character-map-character">ṭ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">v́</Clipboard></td>
            <td></td>
          </tr>
        <tr>
            <td></td>
            <td><Clipboard className="character-map-character">x̣</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        <tr>
            <td></td>
            <td></td>
            <td><Clipboard className="character-map-character">ž</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">δ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ŋ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ñ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ð</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">θ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><Clipboard className="character-map-character">ʌ</Clipboard></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
