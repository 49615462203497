enum VerbType {
  Active,
  Stative,
}

enum VerbClass {
  Directional,
  Motion,
}

enum VerbTransitivity {
  Transitive,
  Intransitive,
  Ditransitive,
}

export interface VerbBase {
  id           : number;
  form         : string;
  oliverioForm : string;
  gloss        : string;
  type         : VerbType;
  class        : VerbClass;
  transitivity : VerbTransitivity;
}

const roots: { [key: number ]: VerbBase } = {
  1 : {
    id           : 1,
    form         : 'pé',
    oliverioForm : 'o-pʰé',
    gloss        : 'to go',
    type         : VerbType.Active,
    class        : VerbClass.Motion,
    transitivity : VerbTransitivity.Intransitive,
  },
};

export default roots;
