import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

interface BibliographyProps {
  type: 'publications' | 'websites' | 'other';
}

interface ReferenceEntry {
  address    : string;
  author     : string;
  entry_type : string;
  key        : string;
  publisher  : string;
  title      : string;
  url        : string;
  year       : string;
}

interface Reference {
  node: ReferenceEntry;
}

export default function Bibliography (props: BibliographyProps): JSX.Element {
  const { type } = props;

  const typeMappings = {
    publications : [
      'article',
      'book',
      'booklet',
      'inbook',
      'incollection',
      'manual',
      'masterthesis',
      'phdthesis',
      'techreport'
    ],
    websites : [ 'misc' ],
    other    : [
      'conference',
      'inproceedings',
      'proceedings',
      'unpublished'
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      allReference(
        limit: 99999,
        sort: { fields: author, order: ASC }
      ) {
        edges {
          node {
            entry_type
            address
            author
            key
            publisher
            title
            url
            year
          }
        }
      }
    }
  `);

  function getFilteredData (): Reference[] {
    return data.allReference.edges.filter((edge: Reference) => typeMappings[type].includes(edge.node.entry_type));
  }

  function renderEntry (entry: Reference): JSX.Element {
    const { address, author, entry_type, key, publisher, url, title, year } = entry.node;

    function renderTitle () {
      if (title?.length) {
        return (
          <span
          className={classnames({
            'citation-title-publication' : typeMappings.publications.includes(entry_type),
          })}
        >{title}</span>
        );
      }

      return null;
    }

    function renderPublisherInfo () {
      if (address?.length && publisher?.length) {
        return (
          <>
            <span className="citation-address">{address}</span>
            {': '}
            <span className="citation-publisher">{publisher}</span>
            {'.'}
          </>
        );
      }

      return null;
    }

    function renderUrlLink () {
      if (url?.length) {
        return (
          <div>
            Online: <a href={url} className="citation-url">{url}</a>.
          </div>
        );
      }

      return null;
    }

    return (
      <li
        className="citation"
        key={key}
      >
        <span className="citation-author">{author}</span>
        {'. '}
        <span>{year}</span>
        {'. '}
        {renderTitle()}
        {'. '}
        {renderPublisherInfo()}
        {renderUrlLink()}
      </li>
    );
  }

  const filteredData = getFilteredData();

  if (filteredData?.length) {
    return (
      <ul>
        {filteredData.map((datum: Reference) => renderEntry(datum))}
      </ul>
    );
  }

  return (
    <div className="spacer-bottom">
      None.
    </div>
  );
}
