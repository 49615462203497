import { Radio, RadioChangeEvent, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { ConjugateTableOptions, conjugateTable, ConjugationTableResponse } from '../../lib/conjugate';
import verbBases from '../../lib/verb-bases';

// Sa:s kiwle:bina
// Sa:s kiwile:bina
// Sa:s	k-i-wi-le:-bi-na
// Bed 	NEG-DIR-1SG.P-go-DESID-NEG
// 'I do not want to go to the bed'

// NOTE: The naming of the values here are very
// important. For all intents and purposes, you can
// treat everything before the `-` as the radio group
// name and everything after as the value. That means
// that each value should only ever have one `-` with
// the option name before and the value after.

export default function Conjugator (): JSX.Element {
  const [ conjugationOptions, setConjugationOptions ] = useState<ConjugateTableOptions>({
    // default values
    tense_aspect          : 'tense_aspect-progressive',
    realis                : 'realis-without',
    mode                  : 'mode-assertive',
    negation              : 'negation-without',
    locative              : 'locative-none',
    instrumental_prefixes : 'instrumental_prefixes-none',
  });

  const [ baseId,              setBaseId ]              = useState<number | null>(null);
  const [ conjugationResponse, setConjugationResponse ] = useState<ConjugationTableResponse | null>(null);

  function handleOptionsChange (event: RadioChangeEvent) {
    const optionValue = event.target.value;
    const optionName = optionValue.split('-')[0];
    setConjugationOptions({ ...conjugationOptions, [optionName]: optionValue });
  }

  useEffect(() => {
    if (baseId && conjugationOptions) {
      setConjugationResponse(conjugateTable(baseId, conjugationOptions));
    }
  }, [baseId, conjugationOptions]);

  function renderOutput () {
    if (conjugationResponse?.error) {
      return `ERROR: ${conjugationResponse.error}`;
    }

    if (conjugationResponse?.forms) {
      return (
        <table className="conjugator-output-table">
          <thead>
            <tr>
              <th className="bold">&nbsp;</th>
              <th className="bold">Singular</th>
              <th className="bold">Plural</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bold">First</td>
              <td>{conjugationResponse.forms['1sg']?.form}</td>
              <td>{conjugationResponse.forms['1pl']?.form}</td>
            </tr>
            <tr>
              <td className="bold">Second</td>
              <td>{conjugationResponse.forms['2sg']?.form}</td>
              <td>{conjugationResponse.forms['2pl']?.form}</td>
            </tr>
            <tr>
              <td className="bold">Third</td>
              <td>{conjugationResponse.forms['3sg']?.form}</td>
              <td>{conjugationResponse.forms['3pl']?.form}</td>
            </tr>
          </tbody>
        </table>
      );
    }
  }

  return (
    <div>
      <div className="conjugator-output">
        {renderOutput()}
      </div>

      <div>
        <Select
          className="conjugator-select"
          showSearch
          placeholder="Select a verbal root"
          optionFilterProp="label"
          options={Object.entries(verbBases).map(([ id, base]) => {
            return ({
              label : `${base.form} (${base.gloss})`,
              value : id,
            });
          })}
          onChange={((index) => {
            setBaseId(index);
          })}
        />

        <div>Tense / Aspect</div>
        <Radio.Group
          onChange={handleOptionsChange}
          value={conjugationOptions.tense_aspect}
        >
          <Radio.Button value="tense_aspect-past_durative">Past Durative</Radio.Button>
          <Radio.Button value="tense_aspect-past_completed">Past Completed</Radio.Button>
          <Radio.Button value="tense_aspect-present">Present</Radio.Button>
          <Radio.Button value="tense_aspect-progressive">Progressive</Radio.Button>
          <Radio.Button value="tense_aspect-potential">Potential</Radio.Button>
        </Radio.Group>

        <div>Realis</div>
        <Radio.Group
          onChange={handleOptionsChange}
          value={conjugationOptions.realis}
        >
          <Radio.Button value="realis-without">without Realis</Radio.Button>
          <Radio.Button value="realis-with">with Realis</Radio.Button>
        </Radio.Group>

        <div>Mode</div>
        <Radio.Group
          onChange={handleOptionsChange}
          value={conjugationOptions.mode}
        >
          <Radio.Button value="mode-none">None</Radio.Button>
          <Radio.Button value="mode-assertive">Assertive</Radio.Button>
          <Radio.Button value="mode-conditional">Conditional</Radio.Button>
          <Radio.Button value="mode-desiderative">Desiderative</Radio.Button>
          <Radio.Button value="mode-hortative">Hortative</Radio.Button>
          <Radio.Button value="mode-imperative">Imperative</Radio.Button>
          <Radio.Button value="mode-interrogative">Interrogative</Radio.Button>
          <Radio.Button value="mode-negative">Negative</Radio.Button>
          <Radio.Button value="mode-reduplication">Reduplication</Radio.Button>
        </Radio.Group>

        <div>Negation</div>
        <Radio.Group
          onChange={handleOptionsChange}
          value={conjugationOptions.negation}
        >
          <Radio.Button value="negation-without">without Negation</Radio.Button>
          <Radio.Button value="negation-with">with Negation</Radio.Button>
        </Radio.Group>

        <div>Locative</div>
        <Radio.Group
          onChange={handleOptionsChange}
          value={conjugationOptions.locative}
        >
          <Radio.Button value="locative-none">None</Radio.Button>
          <Radio.Button value="locative-at_on_a">at / on (a)</Radio.Button>
          <Radio.Button value="locative-toward_i">toward (i)</Radio.Button>
          <Radio.Button value="locative-inside_in_at_a_general_location_o">inside / in / at a general location (o)</Radio.Button>
        </Radio.Group>

        <div>Instrumental Prefixes</div>
        <Radio.Group
          onChange={handleOptionsChange}
          value={conjugationOptions.instrumental_prefixes}
        >
          <Radio.Button value="instrumental_prefixes-none">None</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_mouth_teeth_speaking_la">by mouth / teeth / speaking (la)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_hand-pulling_lu">by hand / pulling (lu)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_hand_la">by hand (la)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_striking_ka">by striking (ka)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_force_striking_laka">by force / striking (láka)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_pressure-pushing_pa">by pressure / pushing (pa)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_foot_na">by foot (ną)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_foot_la">by foot (la)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_extreme_of_temperature_na">by extreme of temperature (na)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_extreme_of_temperature_ala">by extreme of temperature (ala)</Radio.Button>
          <Radio.Button value="instrumental_prefixes-by_power_ceremony_ku">by power / ceremony (ku)</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
}
