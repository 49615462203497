import dayjs from 'dayjs';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import { smartypants } from '../../lib/utils';

export default function ArticlesIndex (): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        limit: 99999,
        filter: { frontmatter: {
          type: {eq: "article"},
          published: {eq: "yes"}
        }}
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
            }
            excerpt
            slug
          }
        }
      }
    }
  `);

  return (
    <>
      {
        data.allMdx.edges.map((edge: {
          node: {
            excerpt: string;
            frontmatter: {
              date: string;
              title: string;
            }
            slug: string;
          },
        }) => {
          const title = edge.node.frontmatter.title;

          return (
            <div
              key={title}
              className="articles-list-entry"
            >
              <Link
                className="articles-list-entry-link"
                to={`/articles/${edge.node.slug}/`}
              >
                {smartypants(title)}
              </Link>
              <div className="articles-list-entry-date">{dayjs(edge.node.frontmatter.date).format('MMMM D, YYYY')}</div>
              <div className="articles-list-entry-excerpt">{edge.node.excerpt}</div>
            </div>
          );
        })
      }
    </>
  );
}
