import { MDXProvider } from '@mdx-js/react';
import { Location } from '@reach/router';
import React, { ReactNode} from 'react';

import SEO from '../../SEO';
import Global from '../../page-components/Global';

import CiteThis from '../../components/CiteThis';

import ArticlesIndex from '../../components/ArticlesIndex';
import Bibliography from '../../components/Bibliography';
import Conjugator from '../../components/Conjugator';
import ContactForm from '../../components/ContactForm';
import CharacterMap from '../../components/CharacterMap';
import LessonsIndex from '../../components/LessonsIndex';

import { smartypants } from '../../lib/utils';

interface PageTemplateProps {
  children    : ReactNode;
  location    : Location;
  pageContext : {
    frontmatter : {
      citable     : string;
      date        : string;
      description : string;
      title       : string;
    }
  }
}

const components = {
  ArticlesIndex,
  Bibliography,
  CharacterMap,
  Conjugator,
  ContactForm,
  LessonsIndex,
};

export default function PageTemplate (props: PageTemplateProps): JSX.Element {
  const { children, pageContext } = props;

  const { citable, date, description, title } = pageContext.frontmatter;

  function renderCiteThis () {
    if (citable === 'no') return null;

    return (
      <CiteThis
        title={smartypants(title)}
        date={date}
      />
    );
  }

  return (
    <Global>
      <SEO
        title={smartypants(title)}
        description={smartypants(description)}
      />

      <div className="page-container">
        <div className="page-content">
          <h1>{smartypants(title)}</h1>

          <MDXProvider components={components}>
            {children}
          </MDXProvider>
        </div>

        {renderCiteThis()}
      </div>
    </Global>
  );
}
