import verbBases from './verb-bases';

export interface ConjugateOptions {
  instrumental_prefixes : string;
  locative              : string;
  mode                  : string;
  negation              : string;
  number                : string;
  person                : string;
  realis                : string;
  tense_aspect          : string;
}

export type ConjugateTableOptions = Omit<ConjugateOptions, 'person' | 'number'>;

export interface ConjugationResponse {
  error ? : string;
  form  ? : string;
}

export interface ConjugationTableResponse {
  error ? : string;
  forms ? : {
    '1sg' : ConjugationResponse;
    '2sg' : ConjugationResponse;
    '3sg' : ConjugationResponse;
    '1pl' : ConjugationResponse;
    '2pl' : ConjugationResponse;
    '3pl' : ConjugationResponse;
  }
}

export function conjugate (baseId: number, options: ConjugateOptions): ConjugationResponse {
  if (!(baseId && options)) return { error: 'Invalid input.' };

  console.log(options);

  return {
    form : verbBases[baseId].form,
  };
}

export function conjugateTable (baseId: number, options: ConjugateTableOptions): ConjugationTableResponse {
  if (!(baseId && options)) return { error: 'Invalid input.' };

  return {
    forms : {
      '1sg' : conjugate(baseId, { ...options, number: 'number-singular', person: 'person-first'  }),
      '2sg' : conjugate(baseId, { ...options, number: 'number-singular', person: 'person-second' }),
      '3sg' : conjugate(baseId, { ...options, number: 'number-singular', person: 'person-third'  }),
      '1pl' : conjugate(baseId, { ...options, number: 'number-plural',   person: 'person-first'  }),
      '2pl' : conjugate(baseId, { ...options, number: 'number-plural',   person: 'person-second' }),
      '3pl' : conjugate(baseId, { ...options, number: 'number-plural',   person: 'person-third'  }),
    }
  };
}
