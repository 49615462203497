import copy from 'copy-text-to-clipboard';
import React from 'react';

interface ClipboardProps {
  children    : string;
  className ? : string;
  label     ? : string;
}

export default function Clipboard (props: ClipboardProps): JSX.Element {
  const { children, className = '', label } = props;

  return (
    <span
      className={className}
      onClick={() => copy(children)}
      title="Click to copy to clipboard."
    >
      {label || children}
    </span>
  );
}
